<template>
  <div class="main">
    <div class="main-title">请选择要学习的内容</div>
    <van-button type="primary"
                round
                size="large"
                color="#FF8B11"
                class="main-latest"
                @click="latestCourse">
      立即学习最新一期
    </van-button>
    <div class="main-list">
      <div class="main-list-cell"
           v-for="(item, index) in periodList"
           :key="index"
           @click="jump(index)">
        <div class="main-list-cell-left">{{ item.seasonName }}</div>
        <div class="main-list-cell-title">{{ item.seasonDesc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, List, Toast } from "vant";
import Repository from "@/common/Repository";

export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
  },
  methods: {
    jump (index) {
      this.$router.push({
        path: "courseList",
        query: {
          id: this.periodList[index].id,
          period: this.periodList[index].seasonName,
        },
      });
    },
    latestCourse () {
      let info = JSON.parse(localStorage.getItem("info"));
      Repository.record(info.unionid, this.currentPeriod.id, this.currentPeriod.title, () => {
        //保存成功
        window.location.href = this.currentPeriod.curriculumUrl;
      }, e => {
        Toast.fail(e)
      })
    }
  },
  data () {
    return {
      periodList: [],
      currentPeriod: {},
    };
  },
  mounted () {
    let time = localStorage.getItem("time")
    let courseId = localStorage.getItem("courseId")
    let courseName = localStorage.getItem("courseName")
    let info = JSON.parse(localStorage.getItem("info"));
    if (time != null) {
      //有学习记录
      let timeStamp = parseInt(time)
      if (new Date().getTime() - timeStamp > 1000 * 60) {
        Repository.record(info.unionid, courseId, courseName, () => {
          //保存成功
        }, e => {
          Toast.fail(e)
        })
      } else {
        //学习时间太短
      }
      localStorage.removeItem("time")
    }
    Repository.getPeriodList(
      (value) => {
        let { studyCurriculum, studySeasonSjVoList } = value.data;
        this.periodList = studySeasonSjVoList;
        this.currentPeriod = studyCurriculum;
      },
      (e) => {
        Toast.fail(e);
      }
    );
    // let time = localStorage.getItem("time")
  },
};
</script>

<style lang="scss" scoped>
.main {
  background: #087bff url("~@/assets/bg_change_info.png") no-repeat 0 0;
  background-size: 100% 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  &-title {
    margin-top: 276px;
    font-size: 16px;
    color: #fff;
    font-weight: bolder;
  }
  &-latest {
    margin: 20px auto;
    width: 80%;
  }
  &-list {
    width: 90%;
    margin: 0 auto;
    &-cell {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 66px;
      background: #fff;
      border-radius: 5px;
      &-left {
        flex-shrink: 0;
        padding: 15px 20px 15px 10px;
        background: url("~@/assets/bg_period.png") no-repeat 0 50%;
        background-size: 100%;
        color: #fff;
        font-weight: bolder;
      }
      &-title {
        padding: 0 10px;
        font-size: 14px;
        font-weight: bolder;
        text-align: left;
      }
    }
  }
}
</style>
