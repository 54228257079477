<template>
  <div class="main-wrapper">
    <div class="main">
      <div class="main-content">
        <div class="main-content-title">参与学习前请先完善你得资料</div>
        <my-step class="main-content-step"
                 current-step="2" />
        <div class="main-content-subtitle">所属团委</div>
        <div v-for="(dep, index) in deptList"
             :key="index"
             class="main-content-dropdown"
             @click="showPicker(index)">
          <div class="main-content-dropdown-text">
            {{ dep.selected != null ? dep.selected.name : "" }}
          </div>
          <van-icon name="arrow-down"
                    size="20px" />
        </div>
        <div class="main-content-subtitle">真实姓名</div>
        <input v-model="realName"
               class="main-content-dropdown"
               placeholder="请输入真实姓名" />
        <div class="main-content-subtitle">电话</div>
        <input v-model="phone"
               class="main-content-dropdown"
               placeholder="请输入电话" />
        <van-button id="submit"
                    type="primary"
                    color="#0189FF"
                    round
                    size="large"
                    @click="jump">确认并提交</van-button>
      </div>
      <van-popup v-model="isShow"
                 position="bottom">
        <van-picker show-toolbar
                    :columns="pickerData"
                    @confirm="dataConfirm"
                    @cancel="dataCancel" />
      </van-popup>
    </div>
  </div>
</template>

<script>
import MyStep from "./MyStep";
import { Popup, Icon, Picker, Button, Toast } from "vant";
import Repository from "@/common/Repository";

export default {
  data () {
    return {
      isShow: false,
      pickerData: [],
      deptList: [
        {
          selected: null,
          options: [
            {
              name: "各市、县(区)团委",
              id: "shixianqu",
            },
            {
              name: "自治区团委",
              id: "zizhiqu",
            },
            {
              name: "区直属学校团委",
              id: "xuexiao",
            },
            {
              name: "区直属机关事业单位团委",
              id: "jiguan",
            },
            {
              name: "区直属派驻团工委",
              id: "zhupai",
            },
            {
              name: "区直属企业团委",
              id: "qiye",
            },
          ],
        },
      ],
      dept: "",
      deptId: -1,
      deepth: 0,
      realName: "",
      phone: "",
      selected: null
    };
  },
  mounted () {
    let { name, id } = this.$route.query;
    this.deptList[0].selected = {
      name,
      id,
    };
    this.getDeptList();
  },
  methods: {
    showPicker (index) {
      this.deepth = index;
      this.pickerData = this.deptList[index].options.map((value) => {
        return value.name;
      });
      this.isShow = true;
    },
    getDeptList () {
      Repository.getDepartment(
        this.deptList[this.deepth].selected.id,
        (value) => {
          this.deptList.push({
            selected: {
              name: "请选择",
              id: -1,
            },
            options: value.data,
          });
        },
        (e) => {
          Toast.fail(e);
        }
      );
    },
    getSubDeptList () {
      Repository.getDepartmentList(
        this.deptList[this.deepth].selected.id,
        (value) => {
          this.deptList.push({
            selected: {
              name: "请选择",
              id: -1,
            },
            options: value.data,
          });
        },
        (e) => {
          Toast.fail(e);
        }
      );
    },
    dataConfirm (value, index) {
      this.isShow = false;
      let selectedObj = this.deptList[this.deepth].options[index];
      this.deptList[this.deepth].selected = {
        name: value,
        id: selectedObj.id,
      };
      this.deptList = this.deptList.slice(0, this.deepth + 1);

      if (this.deepth == 0) {
        this.selected = null
        this.getDeptList();
      } else {
        this.selected = selectedObj
        if (selectedObj.isEnd == "1") {
          this.getSubDeptList();
        }
      }
    },
    dataCancel () {
      this.isShow = false;
    },
    jump () {
      // let selected = this.deptList[this.deptList.length - 1].selected;
      if (this.selected == null) {
        Toast.fail("请选择所属团委");
      } else if (this.realName == "") {
        Toast.fail("请输入真实姓名");
      } else if (this.phone == "") {
        Toast.fail("请输入电话");
      } else {
        let info = JSON.parse(localStorage.getItem("info"));
        Toast({
          message: "请稍后...",
          duration: 0,
        });
        Repository.changeInfo(
          this.realName,
          this.selected.id,
          this.phone,
          info.unionid,
          this.selected.name,
          () => {
            Toast.clear();
            Toast.success("修改成功");
            this.$router.push({
              name: "Step3",
            });
          },
          (e) => {
            Toast.clear();
            Toast.fail(e);
          }
        );
      }
    },
  },
  components: {
    MyStep,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Picker.name]: Picker,
    [Button.name]: Button,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  height: 100%;
  background: #087bff;
}
.main {
  background: #087bff url("~@/assets/bg_change_info.png") no-repeat 0 0;
  background-size: 100% 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  &-content {
    padding: 10px 10px 20px;
    margin-top: 230px;
    width: 90%;
    box-sizing: border-box;
    border-radius: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    &-title {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
    &-step {
      margin: 10px 40px;
    }
    &-subtitle {
      text-align: left;
      font-size: 14px;
      font-weight: bolder;
      margin-bottom: 5px;
    }
    &-dropdown {
      margin-bottom: 10px;
      display: flex;
      padding: 0 10px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 34px;
      border-radius: 5px;
      border: 1px solid #ddd;
      color: #aaa;
    }
  }
}
#submit {
  margin-top: 20px;
}
</style>
