import { render, staticRenderFns } from "./MyStep.vue?vue&type=template&id=0d4c42ab&scoped=true&"
import script from "./MyStep.vue?vue&type=script&lang=js&"
export * from "./MyStep.vue?vue&type=script&lang=js&"
import style0 from "./MyStep.vue?vue&type=style&index=0&id=0d4c42ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d4c42ab",
  null
  
)

export default component.exports