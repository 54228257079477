<template>
  <div class="main">
    <div class="main-title">反馈信息</div>
    <textarea v-model="content"
              class="main-content"
              placeholder="请写下您的反馈内容"></textarea>
    <van-button class="main-btn"
                round
                type="primary"
                size="large"
                color="#0189FF"
                @click="submit">提交</van-button>
  </div>
</template>

<script>
import { Button, Toast } from "vant";
import Repository from "@/common/Repository";

export default {
  components: {
    [Button.name]: Button,
  },
  data () {
    return {
      content: "",
    };
  },
  methods: {
    submit () {
      if (this.content == "") {
        Toast.fail("请填写意见内容");
      } else {
        let info = JSON.parse(localStorage.getItem("info"));
        Toast({
          message: "登录中...",
          duration: 0,
        });
        Repository.applyAdvice(
          info.unionid,
          this.content,
          () => {
            Toast.clear;
            Toast.success("提交成功");
            this.$router.go(-1);
          },
          (e) => {
            Toast.fail(e)
          }
        );
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.main {
  padding: 10px;
  box-sizing: border-box;
  &-title {
    text-align: left;
    font-size: 18px;
    font-weight: bolder;
    color: #000;
    opacity: 0.85;
    margin: 10px 0;
  }
  &-content {
    width: 100%;
    font-size: 14px;
    padding: 5px;
    box-sizing: border-box;
    border: none;
    resize: none;
    min-height: 150px;
  }
  &-btn {
    margin-top: 20px;
  }
}
</style>
