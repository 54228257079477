<template>
  <div>
    <!-- <h2>获取你得公开信息（昵称、头像等）</h2>
    <van-button @click="wxlogin">微信一键授权</van-button> -->
  </div>
</template>

<script>
import { Button, Toast } from "vant";
import { wxLogin } from "@/util/WXUtil";
import { WX_APP_ID } from "@/common/Constant";
export default {
  components: {
    [Button.name]: Button,
  },
  mounted () {
    Toast.loading({
      message: "请稍后...",
      duration: 0,
    });
    wxLogin(WX_APP_ID, "https://qndxx.nxgqt.org/wxAuth");
  },
  methods: {},
};
</script>

<style>
</style>
