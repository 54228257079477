import axios from 'axios';
import { BASE_URL } from './Constant';

export default {
    get(path, params) {
        let url = `${BASE_URL}${path}`
        console.log(url)
        return new Promise((resolve, reject) => {
            axios({
                url,
                params,
                method: 'get'
            }).then(value => {
                if (value.status == 200) {
                    if (value.data.code == 200 && value.data.errorCode == "") {
                        resolve(value.data)
                    } else {
                        reject(value.data.message)
                    }
                } else {
                    reject("系统异常")
                }
            }).catch(() => {
                reject("网络异常")
            })
        })
    },
    post(path, params) {
        let url = `${BASE_URL}${path}`
        console.log(url)
        return new Promise((resolve, reject) => {
            axios.post(url, params).then(value => {
                if (value.status == 200) {
                    if (value.data.code == 200 && value.data.errorCode == "") {
                        resolve(value.data)
                    } else {
                        reject(value.data.message)
                    }
                } else {
                    reject("系统异常")
                }
            }).catch(() => {
                reject("网络异常")
            })
        })
    }
}