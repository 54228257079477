<template>
  <div id="mine">
    <van-col id="info"
             class="item">
      <img id="head"
           :src="headimgurl" />
      <div class="info-name">{{ name }}</div>
      <div class="info-phone">{{ tel }}</div>
    </van-col>
    <van-row id="change"
             class="card item">
      <van-image class="icon"
                 :src="require('../assets/icon_builder.png')"
                 width="20px"
                 height="20px" />
      <span class="strech">{{ dept }}</span>
      <span class="btn"
            @click="changeInfo">修改</span>
    </van-row>
    <van-row id="study"
             class="item card">
      <van-image class="icon"
                 :src="require('../assets/icon_hat.png')"
                 width="20px"
                 height="20px" />
      <span class="strech">青年大学习园地</span>
      <van-button class="submit"
                  type="primary"
                  size="small"
                  round
                  @click="jump">立即学习</van-button>
    </van-row>
    <van-row id="options"
             class="item card">
      <div class="option"
           id="options-course">
        <div>
          <span id="options-course-text">{{ myStudyCount }}</span><span>期</span>
        </div>
        <div>我已学习</div>
      </div>
      <div class="option"
           id="options-person">
        <div>
          <span id="options-person-text">{{ studyDepCount }}</span><span>人</span>
        </div>
        <div>支部本期学习</div>
      </div>
    </van-row>
    <div class="item card menu"
         justify="space-around">
      <div class="menu-option"
           @click="toMenu(0)">
        <van-image width="44"
                   height="44"
                   :src="require('../assets/icon_function.png')" />
        <div class="menu-option-title">微信矩阵</div>
      </div>
      <div class="menu-option"
           @click="toMenu(1)">
        <van-image width="44"
                   height="44"
                   :src="require('../assets/icon_global.png')" />
        <div class="menu-option-title">官方网站</div>
      </div>
      <div class="menu-option"
           @click="toMenu(2)">
        <van-image width="44"
                   height="44"
                   :src="require('../assets/icon_weibo.png')" />
        <div class="menu-option-title">官方微博</div>
      </div>
    </div>
    <van-button @click="toAdvice"
                color="#0189FF"
                id="feedback"
                size="large"
                type="primary"
                round>意见反馈</van-button>
  </div>
</template>

<script>
import { Button, Col, Row, Icon, Image, Toast } from "vant";
import Repository from "@/common/Repository";

export default {
  components: {
    [Button.name]: Button,
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Image.name]: Image,
  },
  data () {
    return {
      name: "",
      tel: "",
      myStudyCount: "",
      studyDepCount: "",
      url1: "",
      url2: "",
      url3: "",
      unionId: "",
      headimgurl: "",
      dept: ""
    };
  },
  mounted () {
    localStorage.removeItem("time")
    localStorage.removeItem("courseId")
    localStorage.removeItem("courseName")
    if (localStorage.getItem("info") == null) {
      //没微信登录
      this.$router.push({ name: "wxLogin" });
    } else {
      let info = JSON.parse(localStorage.getItem("info"));
      this.unionId = info.unionid;
      this.headimgurl = info.headimgurl;
      this.getInfo();
    }
  },
  methods: {
    changeInfo () {
      this.$router.push({
        name: "Step1",
      });
    },
    getInfo () {
      Toast({
        message: "请稍后...",
        duration: 0,
      });
      Repository.getMenu(
        this.unionId,
        (value) => {
          Toast.clear();
          this.myStudyCount = value.data.myStudyCount;
          this.studyDepCount = value.data.studyDepCount;
          this.name = value.data.name;
          this.tel = value.data.tel;
          this.dept = value.data.subjectionDep
          this.url1 = value.data.studyNavigationVxVoList[1].navigationLink;
          this.url2 = value.data.studyNavigationVxVoList[2].navigationLink;
          this.url3 = value.data.studyNavigationVxVoList[0].navigationLink;
          if (this.name == "") {
            //信息未初始化
            this.$router.push({
              name: "Step1",
            });
          }
        },
        (e) => {
          Toast.clear();
          Toast.fail(e);
        }
      );
    },
    toMenu (index) {
      if (index == 0) {
        this.$router.push({ path: '/matrix' })
      } else if (index == 1) {
        window.location = this.url2;
      } else {
        window.location = this.url3;
      }
    },
    jump () {
      this.$router.push({ name: "StudyList" });
    },
    toAdvice () {
      this.$router.push({ path: "/advice" });
    },
  },
};
</script>

<style lang='scss' scoped>
$fontColor: #0189ff;

.button {
  padding: 5px;
  border-radius: 50%;
  background-color: #00f;
  color: #fff;
}
.strech {
  flex-grow: 1;
  text-align: left;
}
.card {
  background: #fff;
  width: 90%;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px #ddd;
  border-radius: 5px;
  padding: 10px;
}
.item {
  margin: 10px auto 0;
}
#mine {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f9ff url("~@/assets/bg_mine.png") no-repeat 0 0;
  background-size: 100% 220px;
  width: 100%;
  #content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #info {
    margin: 110px auto 0;
    width: 90%;
    box-sizing: border-box;
    height: 140px;
    background: url("~@/assets/bg_info.png") no-repeat 0 0;
    background-size: 100% 100%;
    .info-name {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
    }
    .info-phone {
      font-size: 14px;
      margin-top: 10px;
    }
  }
  #head {
    margin-top: -40px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  #change {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    height: 56px;
    .icon {
      margin-right: 5px;
    }
    .btn {
      color: $fontColor;
    }
  }

  #study {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    height: 56px;
    .icon {
      margin-right: 5px;
    }
  }

  #options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .option {
      width: 100%;
      height: 100px;
      border-radius: 10px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-course {
      margin-right: 5px;
      background: #e9f2ff;
      &-text {
        font-size: 36px;
        color: #0189ff;
      }
    }
    &-person {
      margin-left: 5px;
      background: #fef5f0;
      &-text {
        font-size: 36px;
        color: #f89f60;
      }
    }
  }
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    &-option {
      height: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &-title {
        margin-top: 10px;
        color: #777;
      }
    }
  }
}

#feedback {
  width: 90%;
  margin: 20px 10px 10px;
}

.submit {
  font-weight: bolder;
}
</style>
