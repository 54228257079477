<template>
  <div class="main-wrapper">
    <div class="main">
      <div class="main-content">
        <div class="main-content-title">参与学习前请先完善你得资料</div>
        <my-step class="main-content-step"
                 current-step="3" />
        <div class="main-content-treaty">
          <div class="main-content-treaty-title">温馨提示</div>
          <div class="main-content-treaty-content">
            <p>
              同一个微信号多次参加同一期学习的，系统只记录该期内第一次学习的时间和所属单位。
            </p>
            <p>
              更改个人资料时，系统将清除您当期的答题记录，以此避免因为你拥有多个所属单位而造成统计数据出错，请重新参与学习。
            </p>
          </div>
        </div>
        <van-button @click="jump"
                    id="submit"
                    type="primary"
                    size="large"
                    color="#0189FF"
                    round>确认并提交</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import MyStep from "./MyStep";
import { Button } from "vant";
export default {
  components: {
    MyStep,
    [Button.name]: Button,
  },
  methods: {
    jump () {
      this.$router.push({ name: "Mine" });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  height: 100%;
  background: #087bff;
}
.main {
  background: #087bff url("~@/assets/bg_change_info.png") no-repeat 0 0;
  background-size: 100% 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-content {
    padding: 10px 10px 20px;
    margin-top: 230px;
    width: 90%;
    box-sizing: border-box;
    border-radius: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    &-title {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
    &-step {
      margin: 10px 40px;
    }
    &-treaty {
      border-radius: 10px;
      background: #f5f5f5;
      padding: 10px;
      margin: 10px;
      &-title {
        font-size: 16px;
        font-weight: bolder;
      }
      p {
        text-align: left;
      }
    }
  }
}
#submit {
  margin: 20px auto 20px;
  width: 90%;
}
</style>
