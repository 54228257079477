<template>
  <div>
    <div class="my-step-main">
      <div class="my-step-main-step" :class="currentStep >= 1 ? 'checked' : ''">
        1
      </div>
      <div class="my-step-main-line"></div>
      <div class="my-step-main-step" :class="currentStep >= 2 ? 'checked' : ''">
        2
      </div>
      <div class="my-step-main-line"></div>
      <div class="my-step-main-step" :class="currentStep >= 3 ? 'checked' : ''">
        3
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: String,
      required: true,
      default: "1",
    },
  },
};
</script>

<style lang="scss" scoped>
.my-step-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  &-step {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #d1d1d1;
    border-radius: 50%;
    color: #fff;
    font-size: 24px;
  }

  &-line {
    flex: 1;
    height: 1px;
    background: #d1d1d1;
  }
}

.checked {
  background: #fca267;
}
</style>