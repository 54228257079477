<template>
  <div>
    
  </div>
</template>

<script>
import { getUrlParam } from "@/util/WXUtil";
import { Toast } from "vant";
import Repository from "@/common/Repository";
export default {
  data() {
    return {
      result: "",
    };
  },
  mounted() {
    Toast({
      message: "请稍后...",
      duration: 0,
    });
    let code = this.$route.query.code || getUrlParam("code");
    if (code) {
      //获取微信信息接口,提供code给后台这个接口，
      Repository.login(
        code,
        (value) => {
          Toast.clear();
          localStorage.setItem("info", JSON.stringify(value.data));
          this.$router.replace({ name: "Mine" });
        },
        (e) => {
          Toast.clear();
          Toast.fail(e);
        }
      );
    } else {
      Toast.fail("获取不到code！");
      this.$router.replace("/");
    }
  },
};
</script>

<style>
</style>