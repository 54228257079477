<template>
  <div class="matrix-wrapper">
    <div class="matrix">
      <van-image class="matrix-banner"
                 fit="fill"
                 :src="banner" />
      <tab @change="tabChange"></tab>
      <div class="matrix-content">
        <div class="matrix-content-item"
             v-for="(item,index) in currentGroup"
             @click="clickItem(index)"
             :key="index">
          <img :src="imgBase+item.icon"
               class="matrix-content-item-img" />
          <span>{{item.title}}</span>
        </div>
      </div>
    </div>
    <van-popup round
               :style="{width:'70%'}"
               v-model="show">
      <div class="show-data">
        <van-image class="show-data-img"
                   :src="popupData.qr" />
        <span class="show-data-d">长按二维码关注公众号</span>
        <span class="show-data-desc">{{popupData.desc}}</span>
        <a class="show-data-btn"
           :href="popupData.link">查看历史文章</a>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Banner from '@/assets/banner.jpg'
import Tab from '@/components/Tab.vue'
import Repository from '@/common/Repository'
import { Toast, Image, Popup, Button } from 'vant'
export default {
  components: {
    Tab,
    [Image.name]: Image,
    [Popup.name]: Popup,
    [Button.name]: Button
  },
  data () {
    return {
      show: false,
      // imgBase: "http://192.168.3.4:8089",
      imgBase: "https://api.nxgqt.org",
      banner: Banner,
      group1: [],
      group2: [],
      group3: [],
      group4: [],
      currentGroup: [],
      popupData: {
        qr: "",
        desc: "",
        link: ""
      }
    }
  },
  mounted () {
    Repository.getMatrix(value => {
      this.group1 = value.data[0].studyLinkList
      this.group2 = value.data[1].studyLinkList
      this.group3 = value.data[2].studyLinkList
      this.group4 = value.data[3].studyLinkList
      this.currentGroup = this.group1
    }, e => {
      Toast.fail(e)
    })
  },
  methods: {
    clickItem (index) {
      this.popupData = {
        qr: this.currentGroup[index].qrCode,
        desc: this.currentGroup[index].intro,
        link: this.currentGroup[index].link
      }
      this.show = true
    },
    tabChange (index) {
      switch (index) {
        case 0:
          this.currentGroup = this.group1
          break;
        case 1:
          this.currentGroup = this.group2
          break;
        case 2:
          this.currentGroup = this.group3
          break;
        case 3:
          this.currentGroup = this.group4
          break;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.matrix-wrapper {
  background: #fff;
  height: 100%;
}
.matrix {
  background: #fff;
  display: flex;
  flex-direction: column;
  &-banner {
    width: 100%;
    height: auto;
  }
  &-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    box-sizing: border-box;
    margin: 0 auto;
    &-item {
      margin-top: 10px;
      display: flex;
      width: 33.3%;
      box-sizing: border-box;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      &-img {
        width: 70px;
        margin-bottom: 5px;
      }
    }
  }
}
.show-data {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 14px;
  align-items: center;
  &-img {
  }
  &-d {
    font-weight: bold;
  }
  &-desc {
    margin-top: 5px;
    color: #888;
  }
  &-btn {
    color: #fff;
    background: #087bff;
    border-radius: 5px;
    padding: 8px;
    margin-top: 5px;
    font-weight: bolder;
  }
}
</style>
