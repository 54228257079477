import Request from './Request';

export default {
  //获取部门分组信息
  getDepartment (groups, success, fail) {
    return Request.get('/train/subjectionDep/querygroupsList', {
      groups
    }).then(value => {
      success(value)
    }).catch(e => {
      fail(e)
    })
  },
  //获取部门信息
  getDepartmentList (id = 0, success, fail) {
    return Request.get('/train/subjectionDep/queryprentsList', {
      parentId: id
    }).then(value => {
      success(value)
    }).catch(e => {
      fail(e)
    })
  },
  //获取期数
  getPeriodList (success, fail) {
    return Request.get('/train/tStudySeason/querylist').then(value => {
      success(value)
    }).catch(e => {
      fail(e)
    })
  },
  //课程列表
  getCourseList (id, page, success, fail) {
    return Request.get('/train/studyCurriculum/queryList', {
      seasonsNumberId: id,
      page
    }).then(value => {
      success(value)
    }).catch(e => {
      fail(e)
    })
  },
  //用户信息
  login (code, success, fail) {
    return Request.get('/train/wxpbuser/Wxlogin', {
      code
    }).then(value => {
      success(value)
    }).catch(e => {
      fail(e)
    })
  },
  //意见反馈
  applyAdvice (id, content, success, fail) {
    return Request.post('/train/studyFeedback/issert', {
      feekdbackContent: content,
      createUser: id
    }).then(() => {
      success()
    }).catch(e => {
      fail(e)
    })
  },
  //更新用户信息
  //o_O4pv0kgY2tTl0OfNtAZTQx_UZg
  changeInfo (name, subjectionDepNo, tel, unionId, subjectionDep, success, fail) {
    return Request.post('/train/wxpbuser/update', {
      name, subjectionDepNo, tel, unionId, subjectionDep
    }).then(() => {
      success()
    }).catch(e => {
      fail(e)
    })
  },
  //获取学习情况
  getMenu (unionId, success, fail) {
    return Request.get('/train/studyNavigation/queryList', {
      unionId
    }).then((value) => {
      success(value)
    }).catch(e => {
      fail(e)
    })
  },
  //微信矩阵
  getMatrix (success, fail) {
    return Request.get('/train/StudyLink/querylist').then(value => {
      success(value)
    }).catch(e => {
      fail(e)
    })
  },
  //学习记录
  record (createUser, curriculumId, curriculumName, success, fail) {
    return Request.post('/train/studyRecord/save', { createUser, curriculumId, curriculumName }).then(() => {
      success()
    }).catch(e => {
      fail(e)
    })
  },
  //打卡
  sign (unionId, articleNum, releaseDate, success, fail) {
    return Request.post('/train/studyPunch/save', { unionId, articleNum, releaseDate }).then(() => {
      success()
    }).catch(e => {
      fail(e)
    })
  }
}
