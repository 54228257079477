<template>
  <div class="main-wrapper"
       ref="container">
    <div class="main"
         ref="inner">
      <div class="main-title">{{ seasonName }}</div>
      <div class="main-list">
        <div class="main-list-cell"
             v-for="(item, index) in courseList"
             :key="index"
             @click="jump(index)">
          <van-image fit="cover"
                     class="main-list-cell-img"
                     :src="`${baseUrl}${item.mainPicture}`" />
          <div class="main-list-cell-title">{{ item.title }}</div>
          <div class="main-list-cell-period">{{ item.periodsNumber }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, List, Image, Toast } from "vant";
import Repository from "@/common/Repository";

export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [Image.name]: Image,
  },
  mounted () {
    this.loadData()
    this.containerHeight = this.$refs.container.clientHeight
    this.innerHeight = this.$refs.inner.offsetHeight
    this.$refs.container.addEventListener('scroll', this.onScroll)
  },
  data () {
    return {
      isEnd: false,
      page: 1,
      time: 0,
      baseUrl: "https://api.nxgqt.org",
      seasonName: this.$route.query.period,
      courseList: [],
      containerHeight: 0,
      innerHeight: 0
    };
  },
  methods: {
    onScroll (e) {
      this.innerHeight = this.$refs.inner.offsetHeight
      let scrollTop = e.target.scrollTop
      if (scrollTop + this.containerHeight >= this.innerHeight && !this.isEnd) {
        this.loadData()
      }
    },
    jump (index) {
      let info = JSON.parse(localStorage.getItem("info"));
      Repository.record(info.unionid, this.courseList[index].id, this.courseList[index].title, () => {
        //保存成功
        let url = this.courseList[index].curriculumUrl;
        window.location.href = url;
      }, e => {
        Toast.fail(e)
      })
    },
    loadData () {
      Repository.getCourseList(
        this.$route.query.id,
        this.page,
        (value) => {
          if (value.data.records.length != 0) {
            this.courseList = this.courseList.concat(value.data.records)
            this.page++
          } else {
            this.isEnd = true
          }
        },
        (e) => {
          Toast.fail(e)
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  height: 100%;
  overflow: auto;
}
.main {
  background: #087bff url("~@/assets/bg_change_info.png") no-repeat 0 0;
  background-size: 100% 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 276px 0 30px;
  overflow: auto;
  &-title {
    box-sizing: border-box;
    padding-top: 8px;
    font-size: 16px;
    color: #fff;
    font-weight: bolder;
    width: 345px;
    height: 80px;
    background: url("~@/assets/bg_course_title.png") no-repeat 0 0;
    background-size: 100%;
  }
  &-list {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    &-cell {
      margin-top: 25px;
      display: flex;
      width: 45%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 400px;
      background: #fff;
      border-radius: 5px;
      &-img {
        width: 100%;
        height: 300px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        overflow: hidden;
      }
      &-title {
        font-size: 14px;
        font-weight: bolder;
      }
      &-period {
        height: 30px;
        width: 100px;
        margin-bottom: -15px;
        border-radius: 5px;
        background: #dcf1fe;
        line-height: 30px;
        font-size: 12px;
      }
    }
  }
}
</style>
