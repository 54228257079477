import Vue from 'vue'
import VueRouter from 'vue-router'
import ChangeStep1 from '@/components/ChangeStep1'
import ChangeStep2 from '@/components/ChangeStep2'
import ChangeStep3 from '@/components/ChangeStep3'
import AdviceApply from '@/components/AdviceApply'
import StudyList from '@/components/StudyList'
import CourseList from '@/components/CourseList'
import WXAuth from '@/components/WXAuth'
import WXLogin from '@/components/WXLogin'
import Mine from '@/components/Mine'
import Matrix from '@/components/Matrix'
import Sign from '@/components/Sign'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/mine'
  },
  {
    path: '/sign',
    component: Sign
  },
  {
    path: '/matrix',
    component: Matrix
  },
  {
    path: '/mine',
    name: 'Mine',
    component: Mine
  },
  {
    path: '/step1',
    name: 'Step1',
    component: ChangeStep1
  },
  {
    path: '/step2',
    name: 'Step2',
    component: ChangeStep2
  },
  {
    path: '/step3',
    name: 'Step3',
    component: ChangeStep3
  },
  {
    path: '/studylist',
    name: 'StudyList',
    component: StudyList
  },
  {
    path: '/courselist',
    name: 'CourseList',
    component: CourseList
  },
  {
    path: '/wxAuth',
    name: 'wxAuth',
    component: WXAuth
  },
  {
    path: '/wxLogin',
    name: 'wxLogin',
    component: WXLogin
  },
  {
    path: '/advice',
    name: "AdviceApply",
    component: AdviceApply
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
