<template>
  <div class="sign">
    <img class="sign-img"
         :src="imgSrc" />
  </div>
</template>
<script>
import SignSuccess from '@/assets/sign_success.png'
import SignFail from '@/assets/sign_fail.png'
import { Toast } from 'vant'
import Repository from '@/common/Repository'
import { getUrlParam, wxLogin } from '@/util/WXUtil'
import { WX_APP_ID } from '@/common/Constant'

export default {
  mounted () {
    let code = this.$route.query.code || getUrlParam("code");
    if (code) {
      //获取微信信息接口,提供code给后台这个接口，
      Repository.login(
        code,
        (value) => {
          Toast.clear();
          localStorage.setItem("info", JSON.stringify(value.data));
          this.unionId = value.data.unionid
          this.sign()
        },
        (e) => {
          Toast.clear();
          Toast.fail(e);
        }
      );
    } else {
      // let articleNum = getUrlParam("articleNum")    //文章编号
      // let releaseDate = getUrlParam("releaseDate")    //发布日期
      let { articleNum, releaseDate } = this.$route.query

      if (articleNum != null && releaseDate != null) {
        localStorage.setItem("articleNum", articleNum)
        localStorage.setItem("releaseDate", releaseDate)
      }
      if (localStorage.getItem("info") == null) {
        //没微信登录
        Toast.loading({
          message: "请稍后...",
          duration: 0,
        });
        wxLogin(WX_APP_ID, "https://qndxx.nxgqt.org/sign");
      } else {
        let info = JSON.parse(localStorage.getItem("info"));
        this.unionId = info.unionid;
        this.sign()
      }
    }
  },
  data () {
    return {
      unionId: "",
      imgSrc: "",
      signSuccess: SignSuccess,
      signFail: SignFail
    }
  },
  methods: {
    sign () {
      let articleNum = localStorage.getItem("articleNum")    //文章编号
      let releaseDate = localStorage.getItem("releaseDate")    //发布日期
      Repository.sign(this.unionId, articleNum, releaseDate, () => {
        this.imgSrc = this.signSuccess
        localStorage.removeItem("articleNum")
        localStorage.removeItem("releaseDate")
      }, (e) => {
        Toast.fail(e)
        this.imgSrc = this.signFail
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.sign {
  &-img {
    width: 100%;
    height: auto;
  }
}
</style>
