<template>
  <div class="main-wrapper">
    <div class="main">
      <div class="main-content">
        <div class="main-content-title">参与学习前请先完善你得资料</div>
        <my-step class="main-content-step"
                 current-step="1" />
        <div class="main-content-row">
          <div v-for="(item, index) in deptList"
               :key="index"
               class="main-content-row-item"
               @click="jump(index)">
            <img class="main-content-row-item-img"
                 :src="item.icon" />
            <div class="main-content-row-item-title">
              {{ item.subjectionName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyStep from "./MyStep";
import item1 from "@/assets/icon_item1.png";
import item2 from "@/assets/icon_item2.png";
import item3 from "@/assets/icon_item3.png";
import item4 from "@/assets/icon_item4.png";
import item5 from "@/assets/icon_item5.png";
import item6 from "@/assets/icon_item6.png";
export default {
  data () {
    return {
      deptList: [
        {
          subjectionName: "自治区团委",
          icon: item2,
          id: "zizhiqu",
        },
        {
          subjectionName: "各市、县(区)团委",
          icon: item1,
          id: "shixianqu",
        },
        {
          subjectionName: "区直属学校团委",
          icon: item3,
          id: "xuexiao",
        },
        {
          subjectionName: "区直属机关事业单位团委",
          icon: item4,
          id: "jiguan",
        },
        {
          subjectionName: "区直属派驻团工委",
          icon: item5,
          id: "zhupai",
        },
        {
          subjectionName: "区直属企业团委",
          icon: item6,
          id: "qiye",
        },
      ],
    };
  },
  components: {
    MyStep,
  },
  methods: {
    jump (index) {
      this.$router.push({
        path: "/step2",
        query: {
          id: this.deptList[index].id,
          name: this.deptList[index].subjectionName,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  height: 100%;
  background: #087bff;
}
.main {
  background: #087bff url("~@/assets/bg_change_info.png") no-repeat 0 0;
  background-size: 100% 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   height: 100%;
  &-content {
    padding: 10px 10px 10px;
    margin: 230px 0 20px;
    width: 90%;
    box-sizing: border-box;
    border-radius: 20px;
    background: #fff;
    display: flex;
    flex-direction: column;
    &-title {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
    &-step {
      margin: 10px 40px;
    }
    &-row {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 98px;
        height: 98px;
        border-radius: 10px;
        background: #e3f7ff;
        margin-bottom: 10px;
        &-img {
          width: 44px;
          height: 44px;
        }
        &-title {
          margin-top: 5px;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
