<template>
  <div class="tab">
    <div class="tab-item"
         @click="switchItem(0)"
         :class="currentItem==0?'active':''">
      <span class="tab-item-text">区级平台</span>
    </div>
    <div class="tab-item"
         @click="switchItem(1)"
         :class="currentItem==1?'active':''">
      <span class="tab-item-text">市级平台</span>
    </div>
    <div class="tab-item"
         @click="switchItem(2)"
         :class="currentItem==2?'active':''">
      <span class="tab-item-text">县区</span>
    </div>
    <div class="tab-item"
         @click="switchItem(3)"
         :class="currentItem==3?'active':''">
      <span class="tab-item-text">高校</span>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      currentItem: 0,
    }
  },
  methods: {
    switchItem (index) {
      this.currentItem = index
      this.$emit('change', index)
    }
  },
}
</script>
<style lang="scss" scoped>
.tab {
  display: flex;
  box-sizing: border-box;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #333;
  &-item {
    text-align: center;
    flex: 1;
  }
}
.active {
  background: #087bff;
  color: white;
}
</style>
